@import-normalize; /* bring in normalize.css styles */

html {
  background: white;
}

body {
  margin: 0;
  padding: 0;
  font-family:'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

/* Border-box everywhere https://css-tricks.com/box-sizing/ */
html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
  font-family:'Montserrat', sans-serif;
}
body {
  height: 100%;
  width: 100%;
  margin: 0px !important;
}

#root {
  width: 100%;
  height: 100%;
}

#root > div {
  width: 100%;
  height: 100%;
}
.ReactModal__Body--open {
  overflow-y: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}

a {
  text-decoration-color: black;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiInputLabel-outlined.MuiInputLabel-marginDense {
  font-weight: lighter;
  font-size: 14px;
}